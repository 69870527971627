<template>
  <div class="privacy">
    <div class="title">隱私+條款</div>
    <el-divider />
    <div class="content">
      ChunLin網站使用須知<br />
      歡迎瀏覽ChunLin網站，這部份包含了此網站的使用條款。當閣下以任何方式瀏覽此網站時（包括自動或其他方式），即表示閣下同意此等條款；若閣下不同意此等條款，則閣下不獲授權繼續查閱或進入本網站。<br />
      我們保留隨時修改使用條款的權利，任何更新的資訊及條款將即使上載此網站上，並即時生效。閣下有責任在瀏覽本網站時亦細閱此使用條款，若在條款修改後繼續使用本網站，即表示閣下了解及同意此等修改。<br /><br />

      免責聲明<br />
      ChunLin將密切關注本網站的內容，致力為閣下提供最新最準確的資訊。我們不保證網站內容出現任何資料或印刷上的錯誤，任何因使用本網站的資料而導致的損失或破壞，ChunLin不會承擔任何責任。此外，我們將不時更新此網站的內容而不作任何通知。<br /><br />

      連結<br />
      若與此網站進行連結，請聯絡ChunLin，在未取得我們書面同意前，閣下沒有權利使用此網站的任何商標、服務標記、任何受到版權保護的資訊，以及所有的標記。我們亦保留撤消閣下連結本網站的權利。<br /><br />

      產品<br />
      我們盡最大的努力來確保本網站內所發布的產品圖片準確無誤，但請體諒鑑於攝影技術以及屏幕設置的關係，網站內產品的大小與顏色與真實的產品可能存在差異。<br /><br />

      版權<br />
      本網站的所有內容均屬ChunLin所擁有，並保留所有權利。在未取得我們同意之前，所有內容不能被使用、改編、複製或修改。然而，我們亦因應特殊情況或就個別事件處理。<br />
      此網站內所有ChunLin商標及產品名稱均為ChunLin的註冊商標，未經我們同意前嚴禁擅自使用則屬違法。<br /><br />

      私隱政策處理<br />
      為了透過此網站為閣下提供最優質的服務，我們可能要求閣下提供有關登錄及驗證等的有關資料。我們亦制定私隱政策*，以確保閣下的資料受到妥善處理及維持高度保密。我們亦盡一切努力採取足夠措施，以防止任何未經授權取得閣下的個人資料所導致的風險。<br />
      我們只會在以下情況使用閣下的個人資料:<br /><br />

      若我們在使用個人資料前先取得閣下的同意<br />
      若依據法律規定或法院命令下我們必須提供閣下的個人資料<br />
      在一般的情況及受到法律的監管下，我們不會向第三者披露閣下的個人資料。<br /><br />

      ※有關個人資料的使用及保障，請參閱本網站的私隱政策<br />
      適用之管轄法律及司法管轄權<br />
      當進入此網站時，表示您已接受並同意此使用條款，您將受到中華人民共和國法律所管轄，並按照中華人民共和國法律詮釋。如有任何爭議或糾紛，均應根據中華人民共和國法律而作出判決或仲裁。<br />
    </div>
  </div>
</template>

<style lang="less" scoped>
.privacy {
  padding: 0 11.5%;

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    margin: 33px 0 21px;
  }

  .content {
    font-size: 16px;
    font-weight: 400;
    color: #3D3D3D;
    line-height: 24px;
  }
}
</style>
